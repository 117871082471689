import React from 'react';

const Home = () => {
    return (
        <div className='header'>
            <img className="header-img" src="images/soon.png"/>
        </div>
    );
}

export default Home;
